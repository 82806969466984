.btn {

     &.btn-primary,&.btn-info {
          background: $teal;
          background: linear-gradient(to bottom right, lighten($teal, 25%), lighten($teal, 10%));
          border-color: lighten($teal, 15%);
     }

     &.btn-success {
          border-color: darken($green, 5%) !important;

     }

     &.btn-purple {
          background: $purple;
          background: linear-gradient(to bottom right, lighten($purple, 10%), $purple);
          color: $white;
     }

     &.btn-yellow {
          background: $yellow;
          background: linear-gradient(to bottom right, lighten($yellow, 10%), $yellow);
          color: $white;
     }

     min-width: 120px;


}
