#page-form-action {

    .form-control {
        border-radius: 0;
        border-radius: 4px;
        background: $white;
        border-color: hsl(0, 0%, 80%);
        color: hsl(0, 0%, 20%);
        transition: 0s all;
        font-size: 18px;
        padding: 6px 8px;
        height: auto;
        &:focus {
            border-color: #2584ff;
            color: $gray-900;
            outline: solid 1px #2584ff;
            
        }
    }

    .react-datepicker-wrapper .react-datepicker__input-container input {
        border-radius: 0;
        border-radius: 4px;
        background: $white;
        border-color: hsl(0, 0%, 80%);
        color: hsl(0, 0%, 20%);
        transition: 0s all;
        font-size: 18px;
        padding: 6px 8px;
        height: auto;
        &:focus {
            border-color: #2584ff;
            color: $gray-900;
            outline: solid 1px #2584ff;
            
        }
    }

    .form-group:focus-within .form-control-label {
        color: $green;
        position: relative;
    }

    .form-control-label {
        font-size: 20px;
        margin-top: $spacer * 0;
        color: $gray-900;
        transition: 0s all;
        margin-bottom: 0;
    }
    
    .question-address-wrapper {
        .form-control-label {
            font-size: 14px;
            margin-top: 0;
        }
    }

    .question-number {
        color: $gray-600;
    }

    .form-group {
        // margin-bottom: 0;

        p {
            margin-top: 5px !important;
            margin-bottom: 5px !important;
        }
    }

    .question-separator {
        margin-top: -$spacer;
    }

    .upload {
        img {
            max-height: 100px;
            max-width: 200px;
        }
    }

    // make calendar appear on input click
    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }

    .question-list {

        
        border-radius: 4px;

        .indent-icon {
            display: none;
        }

        .question-list {
            margin-bottom: $spacer * 2;

            &:not(:first-child) {
                margin-top: $spacer * 2;
                // border-top: solid 4px #eee;
            }

            .indent-icon {
                position: absolute;
                left: 0;
                color: $green;
                display: block;
            }

            .question-list-inner {
                border: solid 2px $gray-200;
                padding-bottom: $spacer * 2;
                border-radius: 4px;
            }
        }
    }

    .question-wrapper {
        padding:  $spacer * 2;
        border-bottom: solid 1px $gray-300;
        // margin-bottom: -$spacer;
        // opacity: .8;
        background: rgba(0,0,0,.015);

        &.active {
            // opacity: 1;
            background-color: $white !important;
        }
        // &:focus {
        //     opacity: 1;
        //     background-color: $white;
        // }
        // &:hover {
        //     opacity: 1;
        //     background-color: $white;
        // }

        .question-wrapper {
            // border-left: solid 1px $gray-300;
            // background: rgba(44, 196, 228, .05)
            background: rgba(0,0,0,0);

            &:last-child {
                border-bottom: none;
                margin-bottom: -$spacer * 2;
            }

            // &:first-child {
            //     margin-bottom: -$spacer * 2;
            // }

        }
    }

    .item-identifier {
        padding-left: 20px;
        margin-bottom: 0;
        margin-top: $spacer * 2;
        color: $orange;
    }

    .has-all-answers {
        .item-identifier {
            cursor: pointer;
        }
    }

    .has-all-answers-closed {

        cursor: pointer;

        &:hover {
            background-color: $gray-200;
        }

        .item-identifier {
            color: $body-color;
        }
    }

    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 860px;
        // max-width: 95%;
        border-radius: 4px;
    }

    .page-progress {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background: $white;
    }

    .main-wrapper {
        margin-top: 44px;
    }

    $width: 40px;

    .step-tracker {

        width: 100%;
        p {
            font-size: $font-size-sm;
            margin-top: $width / 4;
            margin-bottom: 0;
        }

        .tracker {

            list-style: none;
            padding-left: 0;
            text-align: center;
            margin-bottom: 0;

            //calculate the width based on number of children
            @for $i from 1 through 10 {
                li:first-child:nth-last-child(#{$i}),
                li:first-child:nth-last-child(#{$i}) ~ li {

                    width: 100% / $i;
                }
            }

            li {

                position: relative;
                display: inline-block;
                // transition: all 2s;
                z-index: 3;
                
                .connection, .finished-connection {
                    width: 45%;
                    display: inline-block;
                    position: absolute;
                    top: $width / 2;
                    background: $gray-200;;
                    height: 5px;
                    right: -$width / 2;
                    border-radius: $width;
                }

                .connection {
                    z-index: 2;
                }

                .finished-connection {
                    left: -$width / 2;
                }

                &:last-child {

                    .connection {
                        display: none;
                    }

                }
                &:first-child {

                    // .connection {
                    //     background: $green;
                    // }

                    .finished-connection {
                        display: none !important;
                    }

                }

                &.current {

                    .finished-connection {
                        background: $green;
                    }
                    .wrapper {
                        border: solid 2px $teal;
                        color: solid 2px $teal;
                    }
                }

                .wrapper {

                    display: inline-block;
                    background: white;;

                    height: $width;
                    width: $width;

                    border: solid 2px $gray-200;
                    border-radius: 100%;
                    text-align: center;
                    font-size: $width / 2;
                    padding-top: $width / 8;
                    cursor: pointer;

                    span.not-done {
                        top: -2px;
                        position: relative;
                    }

                    span.done {
                        top: -2px;
                        position: relative;
                    }

                    .done {
                        display: none;
                    }

                }

                &.finished {

                    .connection, .finished-connection {
                        background: $green;;
                        cursor: default;

                    }

                    

                    .wrapper {


                        background: $green;
                        border-color: $green;
                        color: $white;

                        .done {
                            display: inline-block;
                        }

                        .not-done {
                            display: none;
                        }

                    }

                }

            }

        }

    }


    .footer {
        padding-left: 15px;
        padding-right: 15px;
    }
    .page-progress {
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-identifier {
        padding-right: 20px !important;
    }

    .page-identifier-number {
        right: 20px !important;
    }

    @media(max-width: 600px) {
        .upload {
            img {
                margin-bottom: 20px;
                max-height: 300px;
                max-width: 100%;
            }
        }
    
    }

    .react-phone-input {
        display: block;
        width: 100%;
        position: relative;

        .flag-dropdown {
            position: absolute;
            left: 1px;
            top: 1px;
            bottom: 1px;
            width: 45px;
            outline: none !important;
            border: none;
            border-right: solid 1px #ddd;

            button {
                height: 100%;
                width: 45px;
                border: none;
                border-right: solid 1px #ddd;
                border-radius: 4px;
                cursor: pointer;

            }
        }

        input {
            padding-left: 50px;
            display: block;
            width: 100%;
        }
    }

 }


.archk-calendar-sunday {
    background-color: $green;
    color: $white;
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
}
.archk-calendar-not-sunday {
    // background-color: $gray-200;
    color: $gray-600;
    cursor: inherit;
    background-color: white;
    display: inline-block;
    width: 100%;
    border-radius: 4px;
    cursor: default !important;
}

.archk-calendar-wrapper-not-sunday {
    cursor: default !important;
}