.card-title {
    font-weight: bold;
}

.card {
    box-shadow: $box-shadow-sm;
    border-radius: 10px;

    .card-header {
        border-top-right-radius: 10px !important;
        border-top-left-radius: 10px !important;
    }
    .card-footer {
        border-bottom-right-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
    }

}